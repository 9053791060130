@nunito-sans: '@{sol-font-path}/nunito-sans';

@font-face {
  font-family: 'Nunito Sans';
  src: url('@{nunito-sans}/NunitoSans-Light.woff') format('woff'),
  url('@{nunito-sans}/NunitoSans-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('@{nunito-sans}/NunitoSans-LightItalic.woff') format('woff'),
  url('@{nunito-sans}/NunitoSans-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('@{nunito-sans}/NunitoSans-Regular.woff') format('woff'),
  url('@{nunito-sans}/NunitoSans-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('@{nunito-sans}/NunitoSans-SemiBold.woff') format('woff'),
  url('@{nunito-sans}/NunitoSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito Sans';
  src: url('@{nunito-sans}/NunitoSans-Bold.woff') format('woff'),
  url('@{nunito-sans}/NunitoSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}