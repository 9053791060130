.sol-event-item {
  display: flex;
  margin-top: 0;
  margin-bottom: 12px;
  overflow: hidden;
  cursor: pointer;
  text-decoration: none;
  .transition(opacity);
  &:hover {
    opacity: 0.9;
  }
  &-date {
    display: inline-block;
    position: relative;
    background: @event-date-bg;
    color: @event-date-color;
    text-align: center;
    padding-top: 20px;
    width: 80px;
    height: 80px;
    border-radius: @event-border-radius;
    border: 1px solid @event-border-color;
    flex: 0 0 80px;
  }
  &-day {
    font-size: 20px;
    font-weight: 500;
  }
  &-content {
    padding: 4px 10px;
    text-align: left;
    display: inline-block;
    padding-top: 4px;
  }
  &-heading {
    display: block;
    font-size: 24px;
    font-weight: 400;
    font-family: @heading-font;
    margin-top: 0;
    text-decoration: none;
    @media @sm {
      line-height: 27px;
    }
  }
  &-desc {
    display: block;
    margin-bottom: 4px;
    font-size: 13px;
    line-height: 1.4em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &-info {
    .sol-clearfix;
    //font-size: 14px;
    //line-height: 19px;
    padding: 0;
    margin-bottom: 2px;
    @media  @sm {
      margin-bottom: 4px;
    }
    &-item {
      display: inline-block;
      font-size: 16px;
      margin-right: 0px;
      color: tint(@black,35%);
      &:after {
        content: '/';
      }
      &:last-child:after {
        content: '';
      }
    }
  }
}

.sol-solid-events {
  margin-bottom: -13px;
}
