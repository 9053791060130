.sol-startpage-article-item {
  display: block;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 0 20px 30px 200px;
  font-size: 16px;
  line-height: 20px;
  border-bottom:1px solid @gray-2;
  cursor: pointer;
  text-decoration: none;
  min-height: 155px;
  @media @md {
    //padding-left: 160px;
  }
  @media @sm {
    padding-top: 190px;
    padding-right: 20px;
    padding-left: 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
  }
  &.sol-no-img {
    padding-left: 0;
    min-height: auto;
    @media @sm {
      padding-top: 20px;
    }
  }
  & &-img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 180px;
    height: 140px;
    background-position: 50% 50%;
    background-size: cover;
    text-decoration: none;
    border-radius: @border-radius-normal;
    @media @md {
      width: 160px;
    }
    @media @sm {
      width: 100%;
      height: 180px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .transition(background-color);
      border-radius: @border-radius-normal;
    }
  }
  & &-date {
      color: tint(@black,35%);
      margin-bottom: 3px;
      font-weight: 400;
      font-size: 14px;
  }
  & &-heading {
    display: block;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 500;
    font-family: @heading-font;
    line-height: 32px;
    .transition(color);
  }
  & &-desc {
    font-weight: 400;
  }
  & &-event-info {
    .sol-clearfix;
    margin-top: 10px;
    &-item {
      margin-right: 13px;
      font-size: 11px;
      float: left;
      & i {
        margin-right: 6px;
        color: @event-item-icon-bottom;
      }
    }
  }
  &:hover &-img:before {
    background-color: @news-item-img-hover;
  }
  &:hover &-heading {
    color: @news-item-text-hover;
    text-decoration: underline;
  }
}
