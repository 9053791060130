
.sol-top-search-field {
  position: relative;

  input[type=search],
  input[type=text] {
    .no-outline-focus;
    .no-appearance;
    .transition(border-color);
    width: 100%;
    height: 75px;
    padding: 0 75px 0 25px;
    //border: 2px solid @search-top-field-border;
    //border-radius: 4px;
    background-color: @search-top-field-bg;
    color: @search-top-field-text;
    font-family: @main-font;
    font-size: 28px;
    line-height: 34px;
    font-weight: 300;
    border-radius: 0;
    @media @sm {
      height: 60px;
      font-size: 21px;
    }
    @media @xs {
      font-size: 16px;
      border-radius: 0;
    }

/*     @media only screen and (max-width: 379px) and (min-width: 363px) {
      font-size: 14px;
    }

    @media only screen and (max-width: 362px) and (min-width: 346px) {
      font-size: 13px;
    }

    @media only screen and (max-width: 346px) and (min-width: 329px) {
      font-size: 12px;
    } */

    @media only screen and (max-width: 329px) {
      font-size: 11px;
    }

    &:focus {
      border-color: @search-top-field-border-hover;
      box-shadow: inset 0 0px 0px 3px @search-focus-shadow;
      @media @md {
        box-shadow: none;
      }
    }
  }

  button[type=submit],
  button.sol-js-toggle-search {
    .no-appearance;
    .focus-outline;
    .transition(background-color);
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 75px;
    //border-radius: 50%;
    //background-color: @search-top-button-bg;
    background-color: transparent;
    color: @search-top-button-text;
    color: @black;
    font-family: @main-font, sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    display: block;
    @media @sm {
      width: 60px;
      height: 60px;
    }

    & > i.fal {
      position: relative;
      top: 11px;
      font-size: 25px;
      font-weight: 300;
      width: 60px;
      padding-bottom: 20px;
    }
  }
  & > div:hover {
    input[type=search],
    input[type=text] {
      border-color: @search-top-field-border-hover;
    }

    button {
      background-color: @search-top-button-hover;
      color: @search-top-button-text;
    }
  }
}

header .sol-top {
  .sol-top-search-field {
    position: absolute;
    top: 0;
    left: 80px;
    width: 320px;
    z-index: 9;
    .transition(width);
    input[type=search],
    input[type=text] {
      height: 79px;
      border: none;
      border-radius: 0;
      border-right: 1px solid @gray-2;
      padding: 0 50px 0 25px;
      font-size: 20px;
      color: @black;
    }
    button[type=submit] {
      background-color: transparent;
      top: 0;
      right: 0;
      color: @black;
      height: 80px;
    }
  }
  .sol-top-search--closed .sol-top-search-field {
    width: 80px;
    button[type=submit] {
      width: 80px;
      background-color: @white;
      border-right: 1px solid @gray-2;
      border-bottom: 1px solid @gray-2;
    }
  }
}
ul.sv-autocomplete-search-result {
  font-family: @main-font;
  & .sv-autocomplete-result-item {
    padding: 10px 20px;
    & a {
      font-size: 16px;
    }
  }
}
