  body {
    overflow-x: hidden;
  }
  input, textarea, select, button {
    font-family: @main-font;
  }
  p,
  p.font-normal,
  .font-normal,
  p.font-description,
  .font-description {
    a&,
    a {
      color: @normal-link;
      &:hover {
        color: @normal-link-hover;
      }
    }
  }
  
  p.font-description {
    a&,
    a {
      color: @desc-link;     
      &:hover {
        color: @desc-link-hover;
      }
    }
  }  
  
  p.font-quote,
  .font-quote {
    position: relative;
    font-family: @heading-font;
    color: @quote-text;
    font-size: 22px;
    line-height: 29px;
    font-weight: 100;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 55px;
    @media @md{
      padding-left: 35px;
      font-size: 20px;
      line-height: 27px;
    }
    @media @sm{
      font-size: 18px;
      line-height: 25px;
    }
    @media @xs{
      padding-left: 30px;
    }
    &::before {
      .icon(@fa-var-quote-right, 20px);
      position: absolute;
      left: 20px;
      top: 0px;
      opacity: 0.3;
      line-height: 35px;
      @media @md{
        left: 5px;
        line-height: 33px;
      }
      @media @xs{
        left: 5px;
        line-height: 31px;
      }
    }

  }
  
  ol,
  ul {
    &.sol-bullet-list,
    &.sol-numbered-list {
      margin-top: 5px;
      margin-bottom: 20px;
      padding-left: 0px;
      margin-left: 15px;
      list-style: none !important;
      & li {
        position: relative;
        margin-bottom: 7px;
        padding-left: 25px;
        list-style: none !important;
        &::before {
          position: absolute;
          left: 0;
          top: 2px;        
          display: inline-block;
          font-weight: 400;        
        }
      }
      ul{
        padding-left: 0.4rem;
      }
    }

    &.sol-bullet-linklist {
      margin-top: 5px;
      margin-bottom: 20px;
      padding-left: 0px;
      margin-left: 15px;
      list-style: none !important;
      & li {
        position: relative;
        margin-bottom: 7px;
        padding-left: 15px;
        &::before {
          position: absolute;
          left: 0;
          top: 4px;         
          display: inline-block;
          font-weight: 700;        
        }
      }
    }
  }


  .sol-bullet-list li::before {
    .icon(@fa-var-circle, 16px);
    color: @list-icon;
    font-size: 10px;
    margin-top: 9px;
    font-weight: 600 !important;
  }

  .sol-bullet-list ul li::before {
    font-size: 8px;
  }

  .sol-bullet-linklist li::before {
    .icon(@fa-var-angle-right, 16px);
    color: @list-icon;
  }
  
  ol,
  ul {
    &.sol-numbered-list {
      & li {
        counter-increment: step-counter;
        padding-left: 20px;
        &::before {
          content: counter(step-counter) '.';
          color: @list-number;
          font-size: 14px;
          text-align: center;    
          top: 0;     
        }
      }
    }
  }

  .sv-linkicon {
    width: 18px;
    height: 18px;
    max-width: initial;
    max-height: initial;
  }
  .sv-text-portlet .sv-linkicon{
    display: none;
  }