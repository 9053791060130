.sol-mobile-menu {
  h1,
  h2 {
    font-family: @main-font;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
    //font-size: 36px;
    color: @white;
    font-size: 20px;
  }
  h2 {
    font-size: 30px;
  }
  &__item {
    position: relative;
    background-color: white;
    font-family: @main-font;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 400;
    color: @black;
    //margin-bottom: 10px;
    border-bottom: 1px solid @bg-gray;
    a {
      color: @black;
      display: block;
      padding: 0 (8px + 35px + 15px) 0 18px;
      line-height: 50px;
      height: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: none;
      &:hover {
        color: @black;
      }
    }
    button {
      .no-appearance;
      padding: 0;
      font-size: 22px;
      height: 44px;
      width: 44px;
      text-align: center;
      //background-color: @color-1;
      color: @black;
      position: absolute;
      right: 3px;
      top: 3px;
      &::before {
        //Increasing click area
        content: '';
        position: absolute;
        left: -7px;
        top: -7px;
        bottom: -7px;
        right: -7px;
      }
      & i.fal {
        line-height: 35px;
        font-size: 24px;
      }
    }
  }
  &__back {
    .no-appearance;
    display: flex;
    font-family: @main-font;
    font-size: 16px;
    font-weight: 800;
    //height: 50px;
    color: @white;
    //background-color: white;
    border: 1px solid fadeout(@white,20%);
    //margin-left: -15px;
    align-items: center;
    //width: 100vw;
    //margin-bottom: 12px;
    padding: 10px 20px 10px 10px;
    border-radius: @border-radius-normal;
    i.fal {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      font-size: 24px;
      background-color: transparent;
      color: @white;
      //margin-right: 10px;
    }
  }
  &__list {
    position: absolute;
    left: 15px;
    right: 15px;
    padding-bottom: 125px;
  }
  &__links {
    text-align: left;
    padding-top: 25px;
    margin-left: 20px;
    margin-bottom: 30px;
    a, button {
      display: block;
      //font-family: @font-news-cycle;
      //font-weight: normal;
      font-family: @main-font;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      letter-spacing: 0.6px;
      color: @white;
      margin-bottom: 25px;
      text-decoration: none;
      //text-transform: uppercase;
      &:hover {
        color: @white;
        text-decoration: underline;
      }
      i {
        font-size: 20px;
        margin-right: 10px;
      }
    }

    button {
      .no-appearance;
      padding: 0;
    }
  }
  &__startpage-nav .sol-mobile-menu__item button {
    height: 50px;
    width: 100%;
    top: 0;
    right: 0;
    text-align: right;
    padding-right: 20px;
  }
}

/*.sol-mobile-menu-wrapper {
  position: relative;
  @media @md {
    display: block;
  }
}

.sol-mobile-menu-container {
  position: fixed;
  width: 100%;
  margin-top: 80px;
  display: none;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
  z-index: 49;
  height: 0;
  min-height: 0;
  .transition(~'height, min-height', 250ms);
  overflow: hidden;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.43);
  @media @md {
    &.sol-active {
      display: block;
    }
  }

  & > ul {
    border-top: 1px solid @mobile-menu-border;
  }

  & .sol-menu-list {
    width: 100%;
    &-expanded-init {
      display: block;
    }
  }

  & .sol-menu-item {
    position: relative;
    &-link {
      .focus-outline;
      display: block;
      position: relative;
      padding: 10px 49px 10px 19px;
      border-bottom: 1px solid @mobile-menu-border;
      text-decoration: none;
      margin-left: 1px;
      margin-right: 1px;
      color: @mobile-menu-text;

      & .sol-menu-item-display-name {
        font-size: 14px;
        font-weight: 400;
      }
    }
    & .sol-menu-button {
      .no-appearance;
      .focus-outline;
      position: absolute;
      top: 0;
      height: 43px;
      right: 0;
      width: 50px;
      border-left: 1px solid @mobile-menu-border;

      & i {
        position: absolute;
        right: 16px;
        top: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        text-align: center;
        padding-top: 3px;
        padding-left: 3px;
        .transition(all);
      }
    }

    &[data-menu-level='1'] .sol-menu-item-link {
      padding-left: 30px;
    }

    &[data-menu-level='2'] .sol-menu-item-link {
      padding-left: 40px;
    }

    &[data-menu-level='3'] .sol-menu-item-link {
      padding-left: 50px;
    }

    &[data-menu-level='4'] .sol-menu-item-link {
      padding-left: 60px;
    }

    &[data-expanded='true'] > .sol-menu-button {
      & i.fal {
        .rotate(90deg);
      }
    }

    &[data-expanded='true'][data-has-children='false'],
    &[data-is-current='true'] {
      & > .sol-menu-item-link {
        color: @mobile-menu-text-active;
        background-color: @mobile-menu-bg-active;
      }
      & > .sol-menu-button {
        & i.fal {
          color: white;
        }
      }
    }

    &[data-node-type='sv:folder'] {
      & > .sol-menu-button {
        width: 100%;
        border-left: none;
      }
    }
  }
}*/
