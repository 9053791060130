/* Listan med knappar */

.sharebuttonlist {
    list-style-type: none;
    margin: 1em 0;
    padding: 0;
    @media @md {
        margin-bottom: 70px;
    }
}

.sharebuttonlist--horizontal .sharebuttonlist__item {
    display: inline-block;
}

/* Delaknappar */

.sharebutton {
    display: inline-block;
    height: 30px;
    margin: 0 1em .5em 0;
    padding: 0 16px;
    background-color: #ccc;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    line-height: 2;
    text-decoration: none;
    background-size: 27px auto;
    background-position: 12px 50%;
    background-repeat: no-repeat;
}

.sharebutton--facebook {
    background-color: #3b5998;
}

.sharebutton--twitter {
    background-color: #00aced;
}

.sharebutton--linkedin {
    background-color: #007bb6;
}

.sharebutton--gplus {
    background-color: #dd4b39;
}

.sharebutton--email {
    background-color: #57b51d;
}

/* Ikoner på delaknapparna */

.sharebutton--icon-facebook {
    &::after {
        .icon(@fa-var-facebook-f, 20px);
        .fab;
        text-align: center;
        width: 44px;
        padding-top: 12px;
    } //padding: 0 16px 0 32px;
    //background-size: 12px auto;
    //background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDE3IiB3aWR0aD0iOCIgaGVpZ2h0PSIxOCI+PHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTggOS4yaC0yLjd2Ny44aC0zLjN2LTcuOGgtMnYtMi43aDJ2LTIuOGMwLTEuOSAxLjMtMy43IDQuMS0zLjcgMS4yIDAgMS45LjEgMS45LjF2Mi43aC0xLjdjLTEgMC0xLjIuNS0xLjIgMS4zdjIuNGgyLjl2Mi43eiIvPjwvc3ZnPg==');
}

.sharebutton--icon-twitter {
    &::after {
        .icon(@fa-var-twitter, 20px);
        .fab;
        text-align: center;
        width: 44px;
        padding-top: 12px;
    }
}
    .sharebutton--icon-linkedin {
        &::after {
            .icon(@fa-var-linkedin, 20px);
            .fab;
            text-align: center;
            width: 44px;
            padding-top: 12px;
        }
    }
    .sharebutton--icon-email {
        &::after {
            .icon(@fa-var-envelope, 20px);
            .fal;
            text-align: center;
            width: 44px;
            padding-top: 12px;
        }
    }
    /* Dölj text på delaknappen och gör den rund (kombinera med ikoner på knapparna =) */
    .sharebutton--hide-text {
        width: 44px;
        height: 44px;
        margin: 0 .125em;
        padding: 0;
        background-position: 50% 50%;
        border-radius: 100%;
    }
    .sharebutton--hide-text .sharebutton__text {
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
    }