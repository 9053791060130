.sol-landing-page {
  background: @bg-gray url('/webdav/files/System/resources/graphics/Dekor-vansbro.svg') no-repeat 50% 100%;
  background-size: 110% auto;
  border-bottom: 5px solid @primary-color;
  
  h1, h1.font-heading-1 {
    text-align: center;
    margin: 30px 0 20px 0;
  }

  .pagecontent {
    @media @sm {
      width: 100%;

      .sol-widget-decoration {
        margin-bottom: 30px;
      }
    }
    
  }
}
